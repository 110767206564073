import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ButtonV2 } from '@vibrent/electryon';
import { bindActionCreators } from 'redux';
import { initialize } from 'redux-form';
import { connect } from 'react-redux';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faKey } from '@fortawesome/free-solid-svg-icons';
import styles from './styles.scss';
import UserProfileForm from './userprofileForm';
import * as userActions from './actions';
import * as userDetailActions from '../../actions/userActions';
import { AlertComponent } from '../globalMessage';

const UserProfile = (props) => {
  const { userDetail, currentActiveProgram, actions, userData } = props;
  const className = styles['user-profile-container'];
  const { t } = useTranslation();
  const [editMode, setEditMode] = useState(false);

  const joinedRoles = currentActiveProgram.roles &&
    currentActiveProgram.roles.map(role => t(role.displayName));
  const roles = joinedRoles.join('\n');

  useEffect(() => {
    if (_.isEmpty(userData)) {
      const convertedData = {
        firstName: userDetail.firstname,
        lastName: userDetail.lastname,
        email: userDetail.username,
        roles,
        phoneNumber: userDetail.phoneNumber,
      };
      props.actions.updateUserDetails(convertedData);
    }
  }, [userDetail]);

  useEffect(() => {
    const convertedData = {
      firstName: userData.firstName,
      lastName: userData.lastName,
      email: userData.email,
      roles,
      phoneNumber: userData.phoneNumber,
    };
    props.formInitialize(convertedData);
  }, [userData]);

  useEffect(() => {
    if (props.collapseNavbar) {
      props.actions.colapseNavbar(false);
    }
  }, [props.collapseNavbar]);

  const profileAvatar = (firstName, lastName) => {
    const firstNameInitial = firstName ? firstName[0] : '';
    const lastNameInitial = lastName ? lastName[0] : '';

    return (
      <span className="user-profile-image">
        {firstNameInitial}
        {lastNameInitial}
      </span>
    );
  };

  const setViewMode = () => {
    setEditMode(false);
  };

  const onEdit = () => {
    setEditMode(true);
  };

  const goToChangePasswordPage = () => {
    window.location.href = window.localStorage.getItem('keycloakUrl');
  };

  return (
    <div className={`${className}`}>
      <h1>{t('My Profile')}</h1>
      <div className="module-container-wrapper">
        <div className="heading-back" role="presentation" onClick={() => props.history.goBack()}>
          <i className="fa fa-arrow-left" />
          {t('Back')}
        </div>
        <div className="user-actions">
          {!editMode &&
            <ButtonV2
              key="profile-edit-btn"
              muiButtonProps={{
                variant: 'text',
                color: 'primary',
                onClick: onEdit,
                sx: {
                  height: 0,
                  padding: 0,
                  marginRight: '16px',
                  fontWeight: 500,
                },
              }}
            >
              <FontAwesomeIcon icon={faPencil} size="1x" />{t('Edit Profile')}
            </ButtonV2>}
          <ButtonV2
            key="change-pswd-btn"
            muiButtonProps={{
              variant: 'text',
              color: 'primary',
              onClick: goToChangePasswordPage,
              sx: {
                height: 0,
                padding: 0,
                marginRight: '8px',
                fontWeight: 500,
              },
            }}
          >
            <FontAwesomeIcon icon={faKey} />{t('Change Password')}
          </ButtonV2>
        </div>
        <hr />
        <AlertComponent
          id={userActions.UPDATE_USER_PROFILE}
        />
        <div className="user-profile-name-container">
          {profileAvatar(userData.firstName, userData.lastName)}
          <div className="user-name">{userData.firstName} {userData.lastName}</div>
        </div>
        <UserProfileForm
          initialValues={userData}
          viewMode={!editMode}
          internationalPhoneSupportEnabled
          updatingProfile={props.updatingProfile}
          updateUserProfile={actions.updateUserProfile}
          setViewMode={setViewMode}
          updateUserDetails={actions.updateUserDetails}
        />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  userDetail: state.UserDetail.get('userDetail'),
  collapseNavbar: state.UserDetail.get('collapseNavbar'),
  currentActiveProgram: state.UserDetail.get('currentActiveProgram'),
  updatingProfile: state.UserProfile.get('updatingProfile'),
  userData: state.UserProfile.get('userData'),
});

UserProfile.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }).isRequired,
  userDetail: PropTypes.shape({
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    username: PropTypes.string,
    phoneNumber: PropTypes.number,
  }).isRequired,
  userData: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    phoneNumber: PropTypes.number,
  }).isRequired,
  currentActiveProgram: PropTypes.shape([{
    name: PropTypes.string,
    displayName: PropTypes.string,
    roles: PropTypes.arrayOf(PropTypes.shape({})),
  }]).isRequired,
  actions: PropTypes.shape({
    updateUserProfile: PropTypes.func,
    updateUserDetails: PropTypes.func,
    colapseNavbar: PropTypes.func,
  }).isRequired,
  formInitialize: PropTypes.func.isRequired,
  updatingProfile: PropTypes.bool,
  collapseNavbar: PropTypes.bool,
};

UserProfile.defaultProps = {
  updatingProfile: true,
  collapseNavbar: true,
};

const mapDispatchToProps = dispatch => ({
  actions:
    bindActionCreators(
      Object.assign({}, userActions, userDetailActions),
      dispatch,
    ),
  formInitialize: user => dispatch(initialize('VibrentUserProfileForm', user)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserProfile);

export const UserProfileComponent = UserProfile;
